
<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد میزبان
        </b-button>
      </b-col>
    </b-row>
    <div v-if="owners">
      <!-- <div class="d-flex justify-content-between align-items-center">
        <per-page v-model="perPage" class="w-50" />
        <search
          ref="ownersSearchComponent"
          class="w-50"
          v-model="searchInput"
        />
      </div> -->
      <b-row>
        <b-col sm="6" md="3" lg="2">
          <per-page v-model="perPage" class="w-100" />
        </b-col>
        <b-col sm="12" md="7" lg="8">
          <search
              @searchBase="searchBase"
              :selectedFilter="selectedFilter"
              :submitLoading="searchBasesubmitLoading"
              :isSearchResult="isSearchResult"
              ref="ownersSearchComponent"
              class="w-100"
              v-model="searchInput"
          />
        </b-col>
      </b-row>
      <b-card body-class="p-0" class="mt-1">
        <table-head
            ref="ownersTableHeadComponent"
            :items="tableHeadings"
            @sort="sort"
        />
        <b-row
            class="py-1 mx-1 border-bottom"
            v-for="owner in owners"
            :key="owner.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            <b-avatar
                size="lg"
                :src="$http.defaults.fileUrl +owner.avatar.path"
            />
            <a :href="`https://tourinotravel.com/user/host/${owner.token}`" target="_blank">
              <span class="pl-1">{{ owner.first_name }}</span>
            </a>
          </b-col>
          <b-col class="d-flex align-items-center">
            <a :href="`https://tourinotravel.com/user/host/${owner.token}`" target="_blank">
              {{ owner.last_name }}
            </a>
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ owner.mobile }}
          </b-col>
          <b-col class="d-flex align-items-center pl-0">
                  <span class="font-weight-bold" v-if="owner.show_index_page === 1" style="color: #00c700">
                    فعال
                  </span>
            <span v-else>
                  غیرفعال
                </span>
          </b-col>

          <b-col class="d-flex align-items-center pl-0">
                  <span class="font-weight-bold" style="color: #00c700">
                    {{owner.status}}
                  </span>
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ owner.sheba_number }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ owner.card_number }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
                @click="showConfirmModal(owner)"
                variant="flat-danger"
                class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
                @click="showFormModal(owner)"
                variant="flat-secondary"
                class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
          ref="ownersPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />
    </div>
    <b-sidebar
        width="500px"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش میزبان" : "ایجاد میزبان" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="owner_user" class="text-capitalize"> کاربر :</label>
          <b-form-input
              v-if="editMode"
              disabled
              v-model="ownerInput.full_name"
          ></b-form-input>
          <v-select
              v-if="!editMode"
              id="owner_user"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              v-model="ownerInput.user_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :get-option-label="(option) => option.full_name"
              :reduce="(user) => user.id"
              :options="users"
              @search="getOwners"
              placeholder="برای جستجو تایپ کنید"
          >
            <template #option="{ full_name, mobile }">
              {{ full_name }}
              <br />
              <cite>{{ mobile }}</cite>
            </template>
          </v-select>
        </b-form-group>
        <div   v-if="editMode">
          <b-form-radio
              v-model="ownerInput.show_index_page"
              plain
              v-if="editMode"
              name="some-radios3"
              value="0"
          >
            عدم نمایش در ص اصلی
          </b-form-radio>
          <b-form-radio
              v-model="ownerInput.show_index_page"
              plain
              v-if="editMode"
              name="some-radios3"
              value="1"
          >
            نمایش در ص اصلی
          </b-form-radio>

        </div>

        <b-form-group v-if="editMode" class="mt-1">
          <label for="user_mobile" class="text-capitalize"
          >{{ $t("mobile") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              dir="ltr"
              id="user_mobile"
              v-model="ownerInput.mobile"
              type="text"
              placeholder="+989156662233"
          />
        </b-form-group>
        <b-form-group v-if="editMode" class="mt-1">
          <label for="user_first_name" class="text-capitalize"
          >{{ $t("first name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              id="user_first_name"
              v-model="ownerInput.first_name"
              type="text"
          />
        </b-form-group>
        <b-form-group v-if="editMode" class="mt-1">
          <label for="user_last_name" class="text-capitalize"
          >{{ $t("last name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              id="user_last_name"
              v-model="ownerInput.last_name"
              type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="residence_area" class="text-capitalize">
            وضعیت میزبان (پیشفرض روی active باید باشه ، در صورتی که مدارک داشت روی verify) :<span class="text-danger">*</span>
          </label>
          <v-select
              id="residence_area"
              :components="{ OpenIndicator }"
              class="bg-white"
              :clearable="false"
              v-model="ownerInput.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="types"
          />
        </b-form-group>

        <b-form-group v-if="editMode" class="mt-1">
          <label for="email" class="text-capitalize">{{ $t("email") }}:</label>
          <b-form-input id="email" v-model="ownerInput.email" type="text" />
        </b-form-group>

        <b-form-group class="mt-1">
          <label for="card_number" class="text-capitalize">
            شماره کارت :
          </label>
          <b-form-input
              id="card_number"
              v-model="ownerInput.card_number"
              type="number"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="shaba_number" class="text-capitalize">
            شماره شبا :
          </label>
          <b-form-input
              id="shaba_number"
              v-model="ownerInput.sheba_number"
              type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="national_code" class="text-capitalize"> کد ملی : </label>
          <b-form-input
              id="national_code"
              v-model="ownerInput.national_code"
              type="number"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="national_code_image" class="text-capitalize">
            عکس کارت ملی :
          </label>
          <br />
          <div class="float-left w-50 text-center">
            <b-button
                block
                class="mt-1"
                @click="showCropperModal('national_code_image', true, null)"
            >انتخاب عکس</b-button
            >

            <b-button
                :disabled="!national_code_image"
                block
                @click="
                () => {
                  (national_code_image = null),
                    (nationalCardCroppedImage = null);
                }
              "
            >حذف عکس</b-button
            >
          </div>
          <div class="float-right w-50 text-center">
            <img width="90%" :src="nationalCardCroppedImage" />

            <p v-if="national_code_image">{{ national_code_image.name }}</p>
          </div>
        </b-form-group>
        <b-form-group v-if="editMode" class="mt-1">
          <label for="national_code_image" class="text-capitalize">
            عکس پروفایل :
          </label>
          <br />

          <div class="float-left w-50 text-center">
            <b-button
                block
                class="mt-1"
                @click="showCropperModal('avatar', true, null)"
            >انتخاب عکس</b-button
            >

            <b-button
                :disabled="!avatar"
                block
                @click="
                () => {
                  (avatar = null), (avatarCroppedImage = null);
                }
              "
            >حذف عکس</b-button
            >
          </div>
          <div class="float-right w-50 text-center">
            <b-avatar :src="avatarCroppedImage" size="6rem"></b-avatar>
            <!-- <img  width="90%" :src="avatarCroppedImage"/> -->

            <p v-if="avatar">{{ avatar.name }}</p>
          </div>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="address" class="text-capitalize"> آدرس : </label>
          <b-form-input id="address" v-model="ownerInput.address" type="text" />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="national_code_image" class="text-capitalize">
            شماره تماس های اضطراری :
          </label>
          <br />

          <div class="float-left w-50 text-center">
            <b-button block class="mt-1" @click="showEmergencyModal()"
            >ورود شماره تماس ها</b-button
            >
          </div>
          <div class="float-right w-50 text-center">
            <!-- <img  width="90%" :src="avatarCroppedImage"/> -->

            <p v-for="(item, index) in emergencyNumbers" :key="index">
              {{ item.type }}: {{ item.phone }}
            </p>
          </div>
        </b-form-group>


      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
              class="text-capitalize mr-2"
              @click="formModal = false"
              variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <image-cropper
        :nationalCodeImg="nationalCodeImg"
        :imageType="modalType"
        :showCropperModal="showModal"
        @closeCropperModal="closeCropperModal"
        @updateImage="updateImage"
    ></image-cropper>
    <edit-emergency-contact-number
        :showEmergencyContactModal="showEmergencyContactModal"
        :numbers="emergencyNumbers"
        @addNewFiledToNumbers="addNewFiledToNumbers"
        @deleteLastFiledFromNumbers="deleteLastFiledFromNumbers"
        @closeEmergencyNumbersModal="closeEmergencyNumbersModal"
        @saveEmergenyNumbersData="saveEmergenyNumbersData"
    ></edit-emergency-contact-number>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio, BFormSelect,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ImageCropper from "@/components/ImageCropper.vue";
import EditEmergencyContactNumber from "@/components/EditEmergencyContactNumber.vue";

export default {
  components: {
    BFormSelect,
    EditEmergencyContactNumber,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ImageCropper,
    BFormRadio,
  },
  data() {
    return {
      types: [
        "not_data",
        "not_verify",
        "active",
        "verify",
      ],
      isSearchResult:false,
      selectedFilter:[],
      emergencyNumbers: [],
      avatarCroppedImage: null,
      avatar: null,
      nationalCardCroppedImage: null,
      national_code_image: null,
      nationalCodeImg: null,
      showEmergencyContactModal: false,
      showModal: false,
      modalType: "",
      perPage: 10,
      page: 1,
      users: [],
      owners: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "نام",
          slug: "first_name",
          sortable: true,
        },
        {
          title: "نام خانوادگی",
          slug: "last_name",
          sortable: true,
        },
        {
          title: "موبایل",
          slug: "mobile",
          sortable: true,
        },
        {
          title: "نمایش",
          slug: "show_index_page",
          sortable: true,
        },
        {
          title: "وضعیت",
          slug: "show_index_page",
          sortable: true,
        },
        {
          title: "شماره شبا",
          slug: "email",
          sortable: true,
        },
        {
          title: "شماره کارت",
          slug: "card",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      usersCancelToken: undefined,
      ownerInput: {
        id: "",
        user_id: "",
        bio: "",
        phone: "",
        status: "",
        address: "",
        card_number: "",
        email: "",
        gender: "",
        national_code: "",
        newsletters: "",
        sheba_number: "",
        show_index_page: 0,

      },
      show_index_page2: 0,
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getUrlParams();

    this.getOwners();
    this.getUsers();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getOwners();
    },
    perPage(value) {
      this.page = 1;
      this.getOwners();
    },
    page(value) {
      this.getOwners();
    },
  },
  methods: {
    getUrlParams() {
      if ("filter[name]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[name]`];
        this.selectedFilter.push("name");
      }
      if ("filter[email]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[email]`];
        this.selectedFilter.push("email");
      }
      if ("filter[mobile]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[mobile]`];
        this.selectedFilter.push("mobile");
      }
      if ("per_page" in this.$route.query) {
        this.perPage = Number(this.$route.query[`per_page`]);
        console.log(
            "this.userPerPageComponent",
            this.$refs.userPerPageComponent
        );
        this.$refs.userPerPageComponent.perPage = this.perPage;
      }
      if ("page" in this.$route.query) {
        this.page = this.$route.query[`page`];
      }
      console.log("this.selectedFilter", this.selectedFilter);
    },
    searchBase(data) {
      console.log("data", data);
      this.selectedFilter = [];
      if (data.selectedFilter.length > 0) {
        data.selectedFilter.forEach((item) => {
          if (item == "name") {
            this.searchInput = data.searchInput;
          } else if (item == "mobile") {
            this.searchInput = data.searchInput;
          } else if (item == "email") {
            this.searchInput = data.searchInput;
          }
        });
        this.selectedFilter = data.selectedFilter;
      }
      this.searchBasesubmitLoading = true;
      if (this.searchInput) {
        this.isSearchResult = true;
      }
      if (data.type == "delete") {
        this.isSearchResult = false;
      }
      this.getOwners();
    },
    addNewFiledToNumbers() {
      this.emergencyNumbers.push({ phone: "", type: "" });
    },
    deleteLastFiledFromNumbers() {
      this.emergencyNumbers.pop();
    },
    closeEmergencyNumbersModal() {
      this.showEmergencyContactModal = false;
      this.emergencyNumbers=[]
    },
    saveEmergenyNumbersData() {
      console.log("saveEmergenyNumbersData", this.emergencyNumbers);
      this.closeEmergencyNumbersModal();
    },
    showCropperModal(modalType, showModal, nationalCodeImg) {
      this.modalType = modalType;
      this.showModal = showModal;
      this.nationalCodeImg = { path: nationalCodeImg };
    },
    showEmergencyModal() {
      console.log("ssss");
      this.emergencyNumbers=[{ phone: "", type: "" }]
      this.showEmergencyContactModal = true;
    },
    closeCropperModal() {
      this.modalType = "";
      this.showModal = false;
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getOwners();
    },

    async getOwners() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        "filter[name]": this.selectedFilter.includes("name")
            ? this.searchInput
            : "",
        "filter[email]": this.selectedFilter.includes("email")
            ? this.searchInput
            : "",
        "filter[mobile]": this.selectedFilter.includes("mobile")
            ? this.searchInput
            : "",
        per_page: this.perPage,
        page: this.page,
      };

      const requestData = {
        method: "get",
        url: "/admin/users/host",
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.owners = data.data;
            this.paginationData = data.meta;
            this.searchBasesubmitLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.searchBasesubmitLoading = false;
          });
    },
    showConfirmModal(owner) {
      this.$bvModal
          .msgBoxConfirm(
              `آیا از حذف میزبان مطمئن هستید ؟  : ${owner.first_name} ${owner.last_name}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let ownerIndex = 0;
              this.owners = await this.owners.filter((ownerItem, index) => {
                if (ownerItem.id == owner.id) {
                  ownerIndex = index;
                }
                return ownerItem.id !== owner.id;
              });

              const requestData = {
                method: "delete",
                url: `/admin/users/delete/${owner.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `میزبان: ${owner.first_name} ${owner.last_name} با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.owners.splice(ownerIndex, 0, owner);
                  });
            }
          });
    },
    showFormModal(owner) {
      if (owner) {
        this.ownerInput = {
          id: owner.id,
          user_id: owner.id,
          address: owner.address,
          phone: owner.phone,
          status: owner.status,
          card_number: owner.card_number,
          password_confirmation: "",
          permissions:this.filteredPermision(owner.permissions),
          full_name:owner.full_name,
          first_name:owner.first_name,
          last_name:owner.last_name,
          gender:owner.gender,
          email:owner.email,
          mobile:owner.mobile,
          show_index_page:owner.show_index_page,
          emergency_contact_number:owner.emergency_contact_number
        };
        this.emergencyNumbers=owner.emergency_contact_number
        this.editMode = true;
      } else {
        this.ownerInput = {
          id: "",
          user_id: "",
          address: "",
          phone: "",
          status: "",
          card_number: "",
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
    filteredPermision(permissions){
      var filtered = []
      if(permissions.length>0){
        permissions.forEach(permission => {
          filtered.push({name:permission.name})
        })}
      return filtered
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.ownerInput.user_id) {
        errorMessage = "لطفا یک کاربر را انتخاب کنید";
      }
      // if (!errorMessage && !this.ownerInput.phone) {
      //   errorMessage = "لطفا شماره تلفن را وارد کنید";
      // }
      // if (!errorMessage && this.ownerInput.phone.length < 10) {
      //   errorMessage = "شماره تلفن نمی تواند کمتر از 10 کاراکتر باشد";
      // }
      // if (!errorMessage && !this.ownerInput.card_number) {
      //   errorMessage = "لطفا شماره کارت را وارد کنید";
      // }
      // if (!errorMessage && this.ownerInput.card_number.length != 16) {
      //   errorMessage = "شماره کارت باید 16 کاراکتر باشد";
      // }
      // if (!errorMessage && !this.ownerInput.address) {
      //   errorMessage = "لطفا آدرس را وارد کنید";
      // }
      // if (!errorMessage && this.ownerInput.address.length < 15) {
      //   errorMessage = "آدرس نمی تواند کمتر از 15 کاراکتر باشد";
      // }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;
      let formData = new FormData();

      if(this.national_code_image){
        formData.append("national_code_image", this.national_code_image);}
      if(this.ownerInput.user_id){
        formData.append("user_id", this.ownerInput.user_id);}
      if(this.ownerInput.card_number){
        formData.append("card_number", this.ownerInput.card_number);}
      if(this.ownerInput.sheba_number){
        formData.append("sheba_number", this.ownerInput.sheba_number);}
      if(this.ownerInput.show_index_page){
        formData.append("show_index_page", this.ownerInput.show_index_page);}
      if(this.ownerInput.national_code){
        formData.append("national_code", this.ownerInput.national_code);}
      if(this.ownerInput.status){
        formData.append("status", this.ownerInput.status);}
      if(this.ownerInput.address){
        formData.append("address", this.ownerInput.address);}
      console.log("this.emergencyNumbers",this.emergencyNumbers)
      if(this.emergencyNumbers && this.emergencyNumbers.length>0){
        this.emergencyNumbers.forEach((element, index) => {
          formData.append(
              `emergency_contact_number[${index}][type]`,
              element.type
          );
          formData.append(
              `emergency_contact_number[${index}][phone]`,
              element.phone
          );
        });}
      if(this.editMode){
        if(this.ownerInput.gender){
          formData.append("gender", this.ownerInput.gender);}
        if(this.avatar){
          formData.append("avatar", this.avatar);}
        if(this.ownerInput.first_name){
          formData.append("first_name", this.ownerInput.first_name);}
        if(this.ownerInput.last_name){
          formData.append("last_name", this.ownerInput.last_name);}
        if(this.ownerInput.show_index_page){
          formData.append("show_index_page", this.ownerInput.show_index_page);}
        if(this.ownerInput.mobile){
          formData.append("mobile", this.ownerInput.mobile);}
        if(this.ownerInput.email){
          formData.append("email", this.ownerInput.email);}
        if(this.ownerInput.status){
          formData.append("status", this.ownerInput.status);}
        if(this.ownerInput.password){
          formData.append("password", this.ownerInput.password);}
        if(this.ownerInput.password_confirmation){
          formData.append("password_confirmation", this.ownerInput.password_confirmation);}
      }
      const requestData = {
        method:"post",
        url: this.editMode
            ? `/admin/users/update/${this.ownerInput.id}`
            : `/admin/users/create/host`,
        data: formData,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "میزبان با موفقیت اضافه شد";
            if (this.editMode) message = "میزبان با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.users && this.users.length > 0) {
              this.$refs.ownersPaginationComponent.clear();
              this.$refs.ownersSearchComponent.clear();
              this.$refs.ownersTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getOwners();
            this.getUsers();
          })
          .catch((err) => {
            let message = "خطا در ایجاد میزبان";
            if (this.editMode) message = "خطا در ویرایش میزبان";
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
    getUsers(search, loading) {
      if (typeof this.usersCancelToken != typeof undefined) {
        this.usersCancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "/admin/users/get_all_users",
        params: data,
        cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.users = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    updateImage(data) {
      if (data.imageType == "avatar") {
        this.avatarCroppedImage = data.avatarCroppedImage;
        this.avatar = data.avatar;
        console.log("avatar", this.avatar);
      } else if (data.imageType == "national_code_image") {
        this.nationalCardCroppedImage = data.nationalCardCroppedImage;
        this.national_code_image = data.national_code_image;
        console.log("national_code_image", this.national_code_image);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
