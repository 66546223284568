<template>
  <b-modal no-close-on-backdrop hide-header-close v-model="showEmergencyContactModal">
    <div class="text-center">
      <!--Enter new Mobile Number -->
      <!-- ******************************************************************* -->
      <div>
        <div
          style="
            background-color: white;
            padding: 15px;
            width: 100%;
            max-width: 500px;
            border-radius: 15px;
          "
        >
          <div style="overflow: hidden">
            <div class=" font-bolder">
              افزودن شماره تماس دوم (اضطراری)
            </div>
          </div>
          <hr />

          <b-form ref="form" v-model="valid">
            <b-row
              v-for="(item, index) in numbers"
              :key="index"
              justify="center"
            >
              <b-col class="text-center" cols="8" sm="12" md="8">
                <b-form-input
                  v-model="item.phone"
                  :rules="mobileRules"
                  label="شماره تماس"
                  @keydown.space="(event) => event.preventDefault()"
                  outlined
                  required
                  class="text-input-left-iner-align"
                ></b-form-input>
              </b-col>
              <b-col class="d-flex" cols="4" sm="12" md="4">
                <b-form-select
                  v-model="item.type"
                  :options="items"
                  label="نوع شماره"
                  outlined
                ></b-form-select>
              </b-col>
              <b-button
                v-if="numbers.length - 1 == index"
                :disabled="item.phone == ``"
                @click="newField()"
                >افزودن شماره دیگر</b-button
              >
              <b-button
                v-if="
                  numbers.length - 1 === index &&
                  numbers.length !== 1
                "
                @click="deleteField()"
                >حذف</b-button
              >
            </b-row>
          </b-form>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          size="sm"
          rounded
          dark
          :loading="isContinueBtnClick"
          color="primary"
          @click="
            () => {
              saveNumbersData();
            }
          "
          class="float-left"
        >
          تایید
        </b-button>
        <b-button size="sm" class="float-right" @click="closeModal()">
          لغو
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BIcon,
  BIconArrowUp,
  BIconArrowDown,
  BIconPersonFill,
  BForm,
  BFormSelect
} from "bootstrap-vue";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "EditEmergencyContactNumber",
  props: ["numbers", "showEmergencyContactModal"],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    BIcon,
    BIconArrowUp,
    BIconArrowDown,
    BIconPersonFill,
    BForm,
    BFormSelect,
    
  },
  data() {
    return {
      items: ["پذیرش", "اقوام و آشنایان", "همسایه", "سرایدار و نگهبان", "سایر"],

      valid: true,
      snackName: "",
      showPassword: false,
      showConfirmPassword: false,

      mobileRules: [
        (v) => !!v || "شماره تماس را وارد کنید",
        (v) => (v && v.length <= 10) || "شماره تماسحداقل  10 رقم باشد",
      ],
      firstNameRules: [(v) => !!v || "نام خود را وارد کنید"],
      LastNameRules: [(v) => !!v || "نام خانوادگی خود را وارد کنید"],
      passRules: [
        (v) => !!v || "رمز عبور را وارد کنید",
        (v) => (v && v.length >= 8) || "رمز عبور حداقل باید 8 کاراکتر باشد",
      ],
      passConfirmRules: [
        (v) => !!v || "تکرار رمز عبور را وارد کنید",
        (v) =>
          (v && v == this.value.password.value) ||
          "تکرار رمز عبور با رمز عبور مطابقت ندارد",
      ],
      otpRules: [(v) => !!v || "کد پیامک شده را وارد کنید"],
      isContinueBtnClick: false,
      isRegisterBtnClicked: false,
      isResendSmsBtnClick: false,
      isVerifyBtnClick: false,
      isLoginBtnClicked: false,
      isLoginWithOtpBtnClicked: false,
      loginWithOtpCode: false,
      otpErrorMessage: "",
    };
  },

  computed: {
  },
  methods: {
    newField() {
      this.$emit(`addNewFiledToNumbers`);
      // this.numbers.push({ phone: "", type: "" });
    },
    deleteField() {
      this.$emit(`deleteLastFiledFromNumbers`);
      // this.numbers.pop();
    },
    closeModal(){
            this.$emit(`closeEmergencyNumbersModal`);

    },
    saveNumbersData(){
this.$emit(`saveEmergenyNumbersData`);
    }
  },
};
</script>

<style scoped></style>
